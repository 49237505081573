import React from "react";
import "./footer.css";
import { Container } from "react-bootstrap";
import footerlogo from "../../assets/footer/footerlogo.svg";
import Fb from "../../assets/footer/facebook.svg";
import Insta from "../../assets/footer/instagram.svg";
import linkedin from "../../assets/footer/linkedin.svg";
import twitter from "../../assets/footer/twitter.svg";
import playStore from "../../assets/store/playStore.png";
import appStore from "../../assets/store/appStore.svg";

function Footer() {
  return (
    <section id="footer">
      <Container>
        <div className="footer-details">
          <div className="address-section">
            <a href="/#home">
              <img src={footerlogo} alt="logo" className="footer-logo" />
            </a>
            <div className="tag-head mt-3">
              60/49E V 309, 3rd Floor J C Chambers,
              <br /> Opp Regional Passport Office Panampilly Nagar,
              <br /> Ernakulam 682036
            </div>
            <div className="store-icon-div mt-2">
              <img src={playStore} className="store-icon" alt="" />
              <img src={appStore} className="store-icon" alt="" />
            </div>
          </div>
          <div className="scribe-section">
            <div className="footer-subhead">Scribe</div>
            <div className="scribe-links">
              <p className="mt-3">
                <a href="/#homepage">Home</a>
              </p>
              <p>
                <a href="/#about">About Us</a>
              </p>
              <p>
                <a href="/#blog">Blog</a>
              </p>
              <p>
                <a href="/#partner">Partner with us</a>
              </p>
              <p>
                <a href="/#contact">Contact Us</a>
              </p>
            </div>
          </div>
          <div className="help-section">
            <div className="footer-subhead">Help & Support</div>
            <div className="help-links">
              <p className="mt-3">
                <a href="/privacy">Privacy Policy</a>
              </p>
              <p>
                <a href="/termsandconditions">Refund & Cancellation Policy</a>
              </p>
              <p>
                <a href="/partner-terms-and-conditions">Partner Terms and Conditions</a>
              </p>
              <p>
                <a href="/refund-and-cancellation-policy">
                  Terms and Conditions
                </a>
              </p>
            </div>
          </div>
          <div className="media-section">
            <div className="footer-subhead">Follow Us</div>
            <div className="media-icons mt-3">
              <p>
                <a
                  href="https://www.facebook.com/onescribeapp"
                  className="float"
                  target="blank"
                >
                  <div className="f-media">
                    <img className="facebook-img" src={Fb} alt="facebook" />
                  </div>
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/onescribe.app"
                  className="float"
                  target="blank"
                >
                  <div className="f-media">
                    <img className="insta-img" src={Insta} alt="instagram" />
                  </div>
                </a>
              </p>
              <p>
                <a
                  href="https://in.linkedin.com/company/onescribe"
                  className="float"
                  target="blank"
                >
                  <div className="f-media">
                    <img className="linked-img" src={linkedin} alt="linkedin" />
                  </div>
                </a>
              </p>
              <p>
                <a
                  href="https://twitter.com/onescribeapp"
                  className="float"
                  target="blank"
                >
                  <div className="f-media">
                    <img className="twitter-img" src={twitter} alt="twitter" />
                  </div>
                </a>
              </p>
            </div>
            <div className="mail mt-2">
              <a href="mailto:info@onescribe.app">info@onescribe.app</a>
            </div>
            <div className="phone mt-3">
              <a href="tel:+917356777367">+91 7356777367</a>
            </div>
          </div>
        </div>
      </Container>
      <div className="copyright">
        Copyright &copy; 2024 All Rights Reserved Moblio Future Technologies
        Private Limited
      </div>
    </section>
  );
}

export default Footer;

import React from "react";
import "./customers.css";
import PhoneImg from "./../../assets/customers/phone.png";
import star from "./../../assets/customers/star.svg";
import { Container, Col, Row } from "react-bootstrap";

const Customers = () => {
  return (
    <section id="customers">
      <Container>
        <div className="customers-container">
          <Row>
            <Col lg={7} className=" first-container justify-content-center">
              <div className="circle-customer">
              <img src={PhoneImg} alt="phone-img" className="phone-img" />
              </div>
            </Col>
            <Col lg={5} className=" d-flex  justify-content-center center">
              <div className="background-one">

                <div className="customers-details-div">
                  <div className="customers-heading">For Customers</div>
                  <div className="customers-details">
                    <img src={star} alt="" />
                  
                    <span>Cost effective & Time saving </span>
                  </div>
                  <div className="customers-details">
                    <img src={star} alt="" />
                   
                    <span>Predictable budgeting</span>
                  </div>
                  <div className="customers-details">
                    <img src={star} alt="" />
                   
                    <span>Support local business</span>
                  </div>
                  <div className="customers-details">
                    <img src={star} alt="" />
                   
                    <span>Wide variety of subscriptions</span>
                  </div>
                </div>

              </div>
            </Col>
            {/* <Col lg={5} className='customer-main justify-content-center  '>
                      
                      <div className="customers-col">
                        <div className="customers-heading">For Customers</div>
                        <div className="customers-details">
                          <img  src={star} alt="" />
                          <span>Save more, by Subscribing</span>
                        </div>
                        <div className="customers-details">
                          <img src={star} alt="" />
                          <span>Support Local Business</span>
                        </div>
                        <div className="customers-details">
                          <img  src={star}  alt="" />
                          <span>Exclusive feel of first user</span>
                        </div>
                        <div className="customers-details">
                          <img  src={star}  alt="" />
                          <span>wide variety of Subscriptions</span>
                        </div>
                      </div>
                    </Col> */}
          </Row>
        </div>
      </Container>
    </section>
  );
};
export default Customers;

import React, { useState } from "react";
import "./partner.css";
import one from "../../assets/about/one.svg";
import right from "../../assets/partner/checked.svg";
import arrow from "../../assets/partner/underline.svg";
import { Container, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl, config } from "../../components/network/constants/Constants";

function Partner() {
  const [brandName, setBrandName] = useState("");
  const [poc, setPoc] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [url, setUrl] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    trigger,
  } = useForm();

 

  function handleSubmitMessage(e) {
    submitMail();
    setBrandName("");
    setPoc("");
    setPhone("");
    setMail("");
    setUrl("");
    setMessage("");
    reset();
  }

  const submitMail = () => {
    setLoading(true);
    const payload = {
      brand_name: brandName,
      poc_name: poc,
      phone_number: phone,
      email: mail,
      website_url: url,
      message: message,
    };

    console.log("payload: " + JSON.stringify(payload));
    axios
      .post(baseUrl + "web/create-partner-request/", payload)
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const clearInputError = (fieldName) => {
    setError(fieldName, {
      type: "manual",
      message: "",
    });
  };

  return (
    <section id="partner">
      <Container>
        <div className="partner-contents">
          <Row>
            <Col lg={6}>
              <div className="partner-col">
                <img src={one} alt="one" className="one-img" />
                <div className="partner-details">
                  <div className="main-heading partner">
                    Partner with OneScribe
                    <img src={arrow} alt="underarrow" className="underarrow" />
                  </div>
                  <div className="points">
                    <p>
                      <img src={right} className="point-icon" alt="point" />
                      More customers
                    </p>
                    <p>
                      <img src={right} className="point-icon" alt="point" />
                      Faster conversions
                    </p>
                    <p>
                      <img src={right} className="point-icon" alt="point" />
                      Better retention
                    </p>

                  </div>
                <p className="onescribe-site-link">Fill the form or mail us at partnership <a href="https://www.onescribe.app/">onescribe.app</a></p>
                </div>

              </div>
            </Col>
            <Col lg={6} className="partners-form">
              <div className="collaborate-col">
                <div className="collab-form">
                  <div className="collabe-head">Let's Collaborate</div>
                  <form onSubmit={handleSubmit(handleSubmitMessage)}>
                    <div className="textfield-div">
                      <input
                        id="brandName"
                        type="text"
                        required=""
                        placeholder="Brand Name"
                        autoComplete="off"
                        className={`input-field ${
                          errors.brandName && "invalid"
                        }`}
                        {...register("brandName", {
                          required: "Brand Name is Required !",
                        })}
                        onChange={(e) => {
                          clearInputError("brandName");
                          setBrandName(e.target.value);
                        }}
                      />
                      {errors.brandName && (
                        <small className="error-text">
                          {errors.brandName.message}
                        </small>
                      )}
                    </div>
                    <div className="textfield-div">
                      <input
                        id="pocName"
                        type="text"
                        required=""
                        placeholder="POC Name"
                        autoComplete="off"
                        className={`input-field ${errors.pocName && "invalid"}`}
                        {...register("pocName", {
                          required: "POC Name is Required !",
                        })}
                        onChange={(e) => {
                          clearInputError("pocName");
                          setPoc(e.target.value);
                        }}
                      />
                      {errors.pocName && (
                        <small className="error-text">
                          {errors.pocName.message}
                        </small>
                      )}
                    </div>
                    <div className="textfield-div">
                      <input
                        id="phoneNumber"
                        type="tel"
                        required=""
                        placeholder="Phone Number"
                        className={`input-field ${
                          errors.phoneNumber && "invalid"
                        }`}
                        {...register("phoneNumber", {
                          required: "Phone Number is Required !",
                          pattern: {
                            value:
                              /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                            message: "Invalid phone number !",
                          },
                        })}
                        onChange={(e) => {
                          clearInputError("phoneNumber");
                          setPhone(e.target.value);
                        }}
                      />
                      {errors.phoneNumber && (
                        <small className="error-text">
                          {errors.phoneNumber.message}
                        </small>
                      )}
                    </div>
                    <div className="textfield-div">
                      <input
                        id="email"
                        type="email"
                        required=""
                        placeholder="Email"
                        className={`input-field ${errors.email && "invalid"}`}
                        {...register("email", {
                          required: "Email is Required !",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address !",
                          },
                        })}
                        onChange={(e) => {
                          clearInputError("email");
                          setMail(e.target.value);
                        }}
                      />
                      {errors.email && (
                        <small className="error-text">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                    <div className="textfield-div">
                      <input
                        id="websiteURL"
                        type="url" /* Use type="url" for URL validation */
                        required=""
                        placeholder="Website URL"
                        className={`input-field ${
                          errors.websiteURL && "invalid"
                        }`}
                        {...register("websiteURL", {
                          required: "Website URL is Required !",
                          pattern: {
                            // Regular expression for a valid URL
                            value: /^(ftp|http|https):\/\/[^ "]+$/,
                            message: "Invalid URL format !",
                          },
                        })}
                        onChange={(e) => {
                          clearInputError("websiteURL");
                          setUrl(e.target.value);
                        }}
                      />
                      {errors.websiteURL && (
                        <small className="error-text">
                          {errors.websiteURL.message}
                        </small>
                      )}
                    </div>
                    <div className="textfield-div">
                      <textarea
                        id="message"
                        type="text"
                        name="subject"
                        rows="6"
                        placeholder="Write a message"
                        className={`input-msg ${errors.message && "invalid"}`}
                        {...register("message", {
                          required: "Message is Required !",
                          // minLength: {
                          //   value: 10,
                          //   message: "Minimum Required length is 10 !",
                          // },
                        })}
                        onChange={(e) => {
                          clearInputError("message");
                          setMessage(e.target.value);
                        }}
                      ></textarea>
                      {errors.message && (
                        <small className="error-text">
                          {errors.message.message}
                        </small>
                      )}
                    </div>
                    <div className="collabe-btn">
                      <input
                        type="submit"
                        className="collabe-button"
                        value="Submit"
                        onClick={handleSubmit(handleSubmitMessage)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default Partner;




// import React, { useState } from "react";
// import "./partner.css";
// import one from "../../assets/about/one.svg";
// import right from "../../assets/partner/checked.svg";
// import arrow from "../../assets/partner/underline.svg";
// import { Container, Col, Row } from "react-bootstrap";
// import { useForm } from "react-hook-form";
// import axios from "axios";
// import { baseUrl, config } from "../../components/network/constants/Constants";

// function Partner() {
//   const [brandName, setBrandName] = useState("");
//   const [poc, setPoc] = useState("");
//   const [phone, setPhone] = useState("");
//   const [mail, setMail] = useState("");
//   const [url, setUrl] = useState("");
//   const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(false);

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//     setError,
//     trigger,
//   } = useForm();

//   const onSubmit = (data) => {
//     console.log("datazz", data);
//     reset();
//   };

//   function handleSubmitMessage(e) {
//     submitMail();
//     setBrandName("");
//     setPoc("");
//     setPhone("");
//     setMail("");
//     setUrl("");
//     setMessage("");
//     reset();
//   }

//   const submitMail = () => {
//     setLoading(true);
//     const payload = {
//       name: brandName,
//       POC_name: poc,
//       phone: phone,
//       email: mail,
//       URL: url,
//       message: message,
//     };

//     console.log("payload: " + JSON.stringify(payload));
//     axios
//       .post(baseUrl + "web/collab/create/", payload)
//       .then((response) => {})
//       .catch((error) => {
//         setLoading(false);
//       });
//   };

//   const clearInputError = (fieldName) => {
//     setError(fieldName, {
//       type: "manual",
//       message: "",
//     });
//   };

//   return (
//     <section id="partner">
//       <Container>
//         <div className="partner-contents">
//           <Row>
//             <Col lg={6}>
//               <div className="partner-col">
//                 <img src={one} alt="one" className="one-img" />
//                 <div className="partner-details">
//                   <div className="main-heading partner">
//                     Partner with one scribe
//                     <img src={arrow} alt="underarrow" className="underarrow" />
//                   </div>
//                   <div className="points">
//                     <p>
//                       <img src={right} className="point-icon" alt="point" />
//                       More customers
//                     </p>
//                     <p>
//                       <img src={right} className="point-icon" alt="point" />
//                       Faster conversions
//                     </p>
//                     <p>
//                       <img src={right} className="point-icon" alt="point" />
//                       Better retention
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </Col>
//             <Col lg={6}>
//               <div className="collaborate-col">
//                 <div className="collab-form">
//                   <div className="collabe-head">Let's Collaborate</div>
//                   <form onSubmit={handleSubmit(onSubmit)}>
//                     <div className="textfield-div">
//                       <input
//                         id="brandName"
//                         type="text"
//                         required=""
//                         placeholder="Brand Name"
//                         autoComplete="off"
//                         className={`input-field ${
//                           errors.brandName && "invalid"
//                         }`}
//                         {...register("brandName", {
//                           required: "Brand Name is Required !",
//                         })}
//                         onChange={(e) => {
//                           clearInputError("brandName");
//                           setBrandName(e.target.value);
//                         }}
//                       />
//                       {errors.brandName && (
//                         <small className="error-text">
//                           {errors.brandName.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="textfield-div">
//                       <input
//                         id="pocName"
//                         type="text"
//                         required=""
//                         placeholder="POC Name"
//                         autoComplete="off"
//                         className={`input-field ${errors.pocName && "invalid"}`}
//                         {...register("pocName", {
//                           required: "POC Name is Required !",
//                         })}
//                         onChange={(e) => {
//                           clearInputError("pocName");
//                           setPoc(e.target.value);
//                         }}
//                       />
//                       {errors.pocName && (
//                         <small className="error-text">
//                           {errors.pocName.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="textfield-div">
//                       <input
//                         id="phoneNumber"
//                         type="tel"
//                         required=""
//                         placeholder="Phone Number"
//                         className={`input-field ${
//                           errors.phoneNumber && "invalid"
//                         }`}
//                         {...register("phoneNumber", {
//                           required: "Phone Number is Required !",
//                           pattern: {
//                             value:
//                               /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
//                             message: "Invalid phone number !",
//                           },
//                         })}
//                         onChange={(e) => {
//                           clearInputError("phoneNumber");
//                           setPhone(e.target.value);
//                         }}
//                       />
//                       {errors.phoneNumber && (
//                         <small className="error-text">
//                           {errors.phoneNumber.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="textfield-div">
//                       <input
//                         id="email"
//                         type="email"
//                         required=""
//                         placeholder="Email"
//                         className={`input-field ${errors.email && "invalid"}`}
//                         {...register("email", {
//                           required: "Email is Required !",
//                           pattern: {
//                             value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//                             message: "Invalid email address !",
//                           },
//                         })}
//                         onChange={(e) => {
//                           clearInputError("email");
//                           setMail(e.target.value);
//                         }}
//                       />
//                       {errors.email && (
//                         <small className="error-text">
//                           {errors.email.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="textfield-div">
//                       <input
//                         id="websiteURL"
//                         type="url" /* Use type="url" for URL validation */
//                         required=""
//                         placeholder="Website URL"
//                         className={`input-field ${
//                           errors.websiteURL && "invalid"
//                         }`}
//                         {...register("websiteURL", {
//                           required: "Website URL is Required !",
//                           pattern: {
//                             // Regular expression for a valid URL
//                             value: /^(ftp|http|https):\/\/[^ "]+$/,
//                             message: "Invalid URL format !",
//                           },
//                         })}
//                         onChange={(e) => {
//                           clearInputError("websiteURL");
//                           setUrl(e.target.value);
//                         }}
//                       />
//                       {errors.websiteURL && (
//                         <small className="error-text">
//                           {errors.websiteURL.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="textfield-div">
//                       <textarea
//                         id="message"
//                         type="text"
//                         name="subject"
//                         rows="6"
//                         placeholder="Write a message"
//                         className={`input-msg ${errors.message && "invalid"}`}
//                         {...register("message", {
//                           required: "Message is Required !",
//                           // minLength: {
//                           //   value: 10,
//                           //   message: "Minimum Required length is 10 !",
//                           // },
//                         })}
//                         onChange={(e) => {
//                           clearInputError("message");
//                           setMessage(e.target.value);
//                         }}
//                       ></textarea>
//                       {errors.message && (
//                         <small className="error-text">
//                           {errors.message.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="collabe-btn">
//                       <input
//                         type="submit"
//                         className="collabe-button"
//                         value="Submit"
//                         onClick={handleSubmit(handleSubmitMessage)}
//                       />
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </Container>
//     </section>
//   );
// }

// export default Partner;

import React from "react";
import "./helpandsupport.css";
import NavBar from "../navbar/navbar";
import { Container } from "react-bootstrap";
import Footer from "../footer/footer";

const PartnerTandC = () => {
  return (
    <>
      <NavBar />
      <section id="privacy">
        <Container className="privacy-policy-container">
          <div className="main-heading privacy-head">
            Partners Terms and Conditions
          </div>
          <div className="partner-content">
            <h4>
              PLEASE READ THIS CAREFULLY BEFORE PARTNERING WITH ONESCRIBE. BY
              ACCESSING OR USING OUR SERVICE AND PLATFORM YOU ARE AGREEING TO
              THE TERMS BELOW.{" "}
            </h4>
            <p>
              By accepting to onboard Your business on the Onescribe Platform,
              Partner agrees to be bound by the Terms mentioned herein; If You
              do not agree with the Terms You may refrain from availing
              Onescribe Services and listing Your business for subscription
              program on the Onescribe Platform.
            </p>
            <h2>Definitions</h2>
            <ol>
              <li>
                “Date” means a date as mentioned in English calendar and is the
                date upon which something is considered to take effect.
              </li>
              <li>
                “Service Fee (also known as commission)” means the amount
                payable by the Partners to Onescribe, as set out in the form.
              </li>
              <li>
                “Customers” means users who Subscribes through the Platform
              </li>
              <li>
                “Customer App” means online mobile based subscription placing
                application which enables customers to (1) subscribe an Offering
                plan with the Partner business for the purpose of availing
                benefits (2) facilitate a provision of payment (3) to redeem the
                benefits while visiting Partner business via QR code in the App
              </li>
              <li>
                “Customer Data” means any and all identifiable information about
                Customer provided by the Customer via the Platform, to be
                governed by Privacy Policy
              </li>
              <li>
                “Merchant Agreement Form” means form for enrolling Partner
                business
              </li>
              <li>
                “Information” means all specific information the Partners are
                obliged to provide to Onescribe including: (1) Partner business
                name, (2) Owner name (3) logo (4) Offerings rate (5)opening
                hours (6) delivery areas (7)location/ contact number (8) change
                in rate, ownership and location and all other important
                Information.
              </li>
              <li>
                “Plan” means a bundle or a service/ products (collectively
                “Offerings”) with a fixed price over a particular range of time;
                Partners can make different Plans according to their Offerings.
              </li>
              <li>
                “Merchant Application” website means Onescribe developed a
                subscription management application which provides Partners to
                (1) develop a Plan of their own (2) receive cancellation request
                (3) see no of subscribers{" "}
              </li>
              <li>
                “Plan Value” amount payable by Customers upon subscribing a Plan
                with Partner on the Platform for benefits
              </li>
              <li>
                “Parties” means Moblio Future Technologies Private Limited,
                Onescribe and its affiliates and Partner business
              </li>
              <li>
                “Platform” means the Website and Customer Application owned and
                operated by Onescribe
              </li>
              <li>
                “Partner” means the entity/individual being the legal owner of
                the business{" "}
              </li>
              <li>
                “Services” means all service provided by Onescribe to the
                Partners (1) Plan subscribing and Plan listing (2) Demand
                marketing (3) payment facilitating (4) Support to solve any
                issues 24*7/365 (5) Technology Infrastructure
              </li>
              <li>
                “Term” shall mean a period of 12 months commencing from the date
                first mentioned above and any renewal thereof, in accordance
                with its Terms.
              </li>
            </ol>
            <h2>Onescribe’s Obligation:</h2>
            <ol>
              <li>
                Onescribe shall make the Platform available to the Partners for
                listing Plans of their Offerings.
              </li>
              <li>
                Onescribe shall provide the Partner with insights on Customer
                Data to help expand growth and improve the Offerings
              </li>
              <li>
                Onescribe helps Partner’s to promote and market their Offerings
                through the Platform.
              </li>
              <li>
                Onescribe shall facilitate the collection of payments from
                Customers and remit payments to the Partner, minus the Service
                Fee, within [5-7 working days] based on in accordance with the
                agreement, after Customers redeem the benefit.{" "}
              </li>
              <li>
                Onescribe may advise Partners in modifying the Plan for revenue
                generation opportunities and enhancing the Customer experience
                from feedbacks.
              </li>
              <li>
                Onescribe will provide support to Customers and its Partners for
                any issues 24*7/365.
              </li>
              <li>
                Onescribe agrees to keep confidential of all Information
                disclosed by the Partner that is designated as confidential or
                that should reasonably be understood to be confidential.
              </li>
            </ol>
            <p>
              Onescribe take best effort to provide best experience for
              Customers and Partners. But for the avoidance of the doubt it is
              hereby expressly clarified that Onescribe is only responsible for
              providing (1) a Platform to help Partners to list their Offerings
              as Plan for subscription program and sell that Plans to Customers;
              and Onescribe will not be responsible or liable for (2) the
              quality of Offerings listed by Partners and advertisements on
              Platform (3) any delay in Offerings provided by Partners.{" "}
            </p>
            <h4>Partner Obligation:</h4>
            <ol>
              <li>
                Partner will respect the dignity and diversity of Onescribe
                Customers and accordingly will not discriminate against any
                Onescribe Customers on the basis of Discrimination
                Characteristics (as defined) which means discrimination based on
                race, community, religion, disability, gender, sexual
                orientation, age, genetic information, or any other legally
                protected status.
              </li>
              <li>
                Partner is responsible for providing Offerings as per the Plan
                provided by the Partner and ensuring its quality as prescribed
                by Onescribe and Partner. Onescribe reserves the right to
                conduct quality assessments anytime.
              </li>
              <li>
                Partner shall verify the Customers with the subscription to
                their Offerings as required (as defined) through scanning the QR
                code from Customer App from Merchant Application available on
                mobile.
              </li>
              <li>
                Partner shall notify the Onescribe of any price changes at least
                [30] days in advance. Onescribe will update the Platform
                accordingly.
              </li>
              <li>
                Partner shall provide necessary documentation and reports
                related to the Offerings as requested by Onescribe to ensure
                compliance
              </li>
              <li>
                Partner shall notify Onescribe in writing of any change in
                location, ownership or business operations at least [30] days
                prior to the change. Onescribe reserves the right to terminate
                the Merchant Agreement if the changes are deemed to affect the
                quality or nature of the Offerings.
              </li>
              <li>
                Partner Shall ensure that the Information provided to Onescribe
                is current and accurate, including but not limited to the
                Partner name, address, contact telephone number, email,
                manager/contact person details, opening hours, menu(s), price
                lists, taxes, and other relevant information
              </li>
              <li>
                Partner shall process and execute the subscribed Customers
                promptly
              </li>
              <li>
                Partner acknowledges and agrees that in the event the Customer’s
                experience with the Partner and the Partner Services is hampered
                on account of acts and omissions attributable to the Partner,
                including but not limited to, giving benefits as defined,
                treating Customers, Onescribe reserves the right to take
                appropriate action in accordance with its policies, which may be
                amended from time to time.
              </li>
              <li>
                Partner hereby agrees that if it provides a Plan and Customer
                subscribes to it via Customer App, Partner shall be liable to
                provide that Offering till the date the Plan get expired.{" "}
              </li>
              <li>
                Partner shall contact a Customer if a Plan cannot be processed
                as requested or to clarify the details of a Plan on particular
                date, if required.
              </li>
              <li>
                Partner shall remove any/all Offerings unavailable with the
                Partner from time to time.
              </li>
              <li>
                Partner shall address and resolve all Customer complaints
                received by Onescribe relating to the efficacy, quality or any
                other issues relating to the Offerings. Further the Partner
                shall be solely liable and bound to take action on complaints
                raised by Customer pertaining to the Offerings.{" "}
              </li>
              <li>
                In case any Customer has any claim or damage against any
                Offerings provided by the Partner, the Partner shall be solely
                responsible for the same and shall be liable for paying
                damageable amount to Customer
              </li>
              <li>
                Partner shall not engage in any fraudulent activity or misuse
                any benefits extended by Onescribe to Customers and shall be
                liable to Onescribe in the event Onescribe becomes aware of any
                fraudulent activity undertaken by the Partner
              </li>
              <li>
                Partner shall not charge any extra fee from the Customer on the
                subscribed Plan, until any Offerings is provided extra and is
                out of Plan norms.
              </li>
              <li>
                Partner shall ensure to update the Merchant Application as and
                when the latest version of the application is available
              </li>
              <li>
                Partner shall make sure that Offerings are not shared, and any
                loss incurred due to such activities is bound by Partners only,
                Onescribe is not liable for any such activities.
              </li>
            </ol>
            <p>
              Partner understands and agrees that having similar Offering names
              in the Plan of two or more Partners operating from the same
              location may cause confusion for the Customers and hamper their
              experience on the Platform. Make sure to define unique Offering
              names.
            </p>
            <p>
              Partner acknowledges and agrees that Onescribe will use its best
              endeavors to ensure the Platform is not misused by the Customer
              for subscribing Offerings. In the event of any such incident,
              Partner undertakes to report Onescribe immediately through
              Merchant Application.
            </p>
            <p>
              It is further clarified that, in the event, the Partner Offerings
              are found to be in violation of this term, Onescribe shall notify
              the Partner by giving [30]day written notice to make necessary
              changes and correct it for better Customer experience. However,
              incase Partner fails to correct it, then Onescribe reserves right
              to take appropriate action, which may include removing the
              Partner, in accordance with its policies and these Terms.{" "}
            </p>
            <br></br>
            <h4>Onescribe Offering Plan:</h4>
            <p>
              Offerings plan benefits cannot be combined with any other offers,
              discounts and or deals, and can be redeemed by the Customer only
              with QR code in Customer App.
            </p>
            <h4>Promotions & Advertisement:</h4>
            <p>
              Partner hereby acknowledges and agrees that it shall determine and
              make available promotions to Customers at its sole discretion.
              ‘Promotion” means special offers, early sales, new Offerings,
              special events and that are made available exclusive to subscribed
              Customers with the Partners via Onescribe Platform.{" "}
            </p>
            <p>
              Partner at its sole discretion shall determine the details of the
              Promotions. Partner also agrees that the cost of the Promotions
              shall be completely taken by the Partner.
            </p>
            <p>
              Partner hereby agrees that Onescribe shall have no role or
              responsibility towards the Promotions and will not be liable to
              Partner or Customer for any claim relating to the Promotions.
              Partner shall not have the right to use the Onescribe name or
              Onescribe logo in any independent promotions or marketing or
              advertisement, or in any other manner, without prior written
              consent of Onescribe.
            </p>
            <p>
              Onescribe will determine in its sole discretion, the placement and
              positioning of advertisement (“Advertisements” shall means in app
              advertisement) from the Partner on the Onescribe Platform.{" "}
            </p>
            <p>
              Onescribe does not guarantee the reach or performance of the
              advertisement. Partner is solely liable for compliance with all
              applicable laws relating to the promotions and advertisements on
              the Platform. Partner at its sole discretion shall determine the
              content and validity of the Advertisements (charges applicable).{" "}
            </p>
            <p>
              Partner may at any time opt out of Advertisements without any
              prior notice to Onescribe. Onescribe reserves the right to
              discontinue the Advertisements at its own discretion at any time,
              without any notice or liability to the Partner if the same is
              necessitated due to legal or regulatory requirements. In case of
              occurrence of such discontinuance, the Partner will be provided
              with adequate reasons for discontinuation of Advertisements in due
              course.
            </p>
            <p>
              For any help or queries with regard to Advertisements, You may
              reach out via the help on the Merchant Application
            </p>
            <br></br>
            <h4>License:</h4>
            Partner grants Onescribe an unrestricted, non-exclusive, royalty
            free license in respect of all Content (defined hereinabove) and
            Information provided to Onescribe by the Partner for the purpose of
            inclusion on the Platform and as maybe otherwise required under the
            Merchant Agreement. This includes, but not limited to (1) use of
            Partners name in the context of Google ad-words to support
            advertising and promotions of Offerings on internet which may be
            undertaken by Onescribe (2) all or any portion in the marketing
            material which will be made by Onescribe for the purpose of its
            business.
            <p>
              Partner also grants to Onescribe a royalty-free, perpetual,
              irrevocable, non-exclusive license to use, copy, modify, adapt,
              translate, publish and distribute world-wide any Content,
              Information or material for the purposes of providing Services
              under these Terms or to or for the purposes of advertising and
              promotion of the Onescribe Platform. Partner agrees that all
              Content, information or material provided to Onescribe that is
              published on the Platform, may be relied upon and viewed by
              Customers to enable them to make informed decisions at the
              pre-purchase stage.
            </p>
            <h4>Fees & Payments:</h4>
            <p>
              Partner acknowledges and agrees that the Platform only provide
              payment through Electronic Payment Mechanism.
            </p>
            <p>
              Partner acknowledges and agrees that any amount which is collected
              by Onescribe for, and on behalf of, the Partner in accordance with
              these Terms, shall be passed on by Onescribe to the Partner
              subject to the deduction of the Service Fee and other taxes
              applicable .
            </p>
            <ol>
              <li>
                Partner agrees to pay Onescribe a Service Fee of 2-15% of the
                transaction value, based on location, type of business and other
                internal assessment.
              </li>
              <li>
                Payments collected from the Customers will be remitted to the
                Partner, minus the Service Fee, within [5-7 working days] basis,
                after Customer redeeming the Offerings.
              </li>
              <li>
                Partner shall get the cost of one usage (Plan price) as refunds
                that are initiated by Customers as per Cancellation T&C.
              </li>
            </ol>
            <h4>Confidentiality:</h4>
            <p>
              Other than for provision of providing Offerings, Onescribe does
              not share any other information of the Partner with third parties
              unless requisitioned by government authorities.
            </p>
            <p>
              Other than for the purpose of availing Services from Onescribe,
              the Partner shall not disclose any confidential information about
              Onescribe, including but not limited to these Terms, its business
              strategies, pricing, revenues, expenses, Customer Data, and any
              other Information to third parties.
            </p>
            <h4>Suspension/ Termination:</h4>
            <p>
              This Agreement shall commence on the Date and shall continue for a
              period of [one year], unless terminated earlier in accordance with
              the terms herein;
            </p>
            <p>
              Onescribe may terminate the arrangement or suspend the Partner
              with immediate effect if:
            </p>
            <ol>
              <li>
                The Partner fails to conduct its business (Offerings) in
                accordance with these Terms and /or in accordance with the
                Information given to Onescribe
              </li>
              <li>
                The user experience for the Partner is not found satisfactory as
                per the Onescribe standards and that provided in the Plan;
              </li>
              <li>
                Upon the happening of any of the insolvency events such as
                bankruptcy, appointment of new owner, winding up, change in
                location, dissolution;
              </li>
              <li>
                Onescribe identifies any fraudulent or suspicious activity on
                the Partner’s account;
              </li>
              <li>
                Partner fails to comply with Applicable Law and/or these Terms;
              </li>
              <li>
                Any Information provided by the Partner to Onescribe, including
                on Onescribe Platform, is found to be false, misleading or
                inaccurate,
              </li>
              <li>
                If the Partner’s business property/ store is undergoing
                renovation;
              </li>
            </ol>
            <p>
              Either Party may terminate this Agreement by providing [30] days
              written notice to the other Party.
            </p>
            <p>
              Either Party may terminate this Agreement immediately upon written
              notice if the other Party breaches any material term of this
              Agreement and fails to cure such breach within [15] days of
              receiving written notice of the breach.
            </p>
            <h4>Limitation of Liability:</h4>
            <p>
              In no event shall either Party be liable for any indirect,
              incidental, consequential, or punitive damages arising out of or
              in connection with this Agreement.{" "}
            </p>
            <h4>Use of Customer Data:</h4>
            <p>
              Partner agrees that Partner shall only use Customer Data in
              fulfilling the Offerings in comply the Partners obligation in this
              Term. Partner represents, warrants, and covenants that it will not
              resell, broker or otherwise disclose any Customer Data to any
              third party, in whole or in part, for any purpose whatsoever.
              Partner agrees that it will not copy or otherwise reproduce any
              Customer Data for any purpose. Partner also agrees it shall not
              use the Customer Data for sending any unsolicited message
            </p>
            <h4>Warranty & Indemnification:</h4>
            <p>
              Partner agrees to indemnify, defend, and hold harmless Onescribe
              (its directors, officers, agents, representatives and employees)
              and its affiliates from any claims, damages, losses, or expenses
              arising from the Partner's breach of this Agreement or its
              provision of Offerings.
            </p>
            <p>
              Partner warrants that if the Partner ceases to do business, closes
              operations for a material term or is otherwise unable to provide
              Offerings to Customers it will inform Onescribe promptly before 30
              days. Where the Partner neglects to do so, whether by omission or
              fault, Onescribe shall not be held responsible for any
              liabilities, whether financial or otherwise.
            </p>
            <p>
              Onescribe does not guarantee or warrant that the Platform,
              Merchant Application, Onescribe server/ devices, software,
              hardware or services will be free from defects or malfunctions. If
              errors occur, Onescribe will use its best endeavors to resolve
              these as quickly as possible.
            </p>
            <h4>Disclaimer:</h4>
            <p>
              Onescribe provides Platform on “as is” and “as available” basis
              and makes no warranties or representations about the accuracy or
              completeness of the content and data on the Platform or the
              Onescribe services. Onescribe is not responsible for any loss
              suffered by the Partner as a result of incorrect banking or
              taxation or other financial Information provided by the Partner.{" "}
            </p>
            <h4>Miscellaneous:</h4>
            <ol>
              <li>
                Amendments: Any amendments to this Agreement must be in writing
                and signed by both Parties.
              </li>
              <li>
                Indemnity: You shall indemnify, defend and hold harmless
                Onescribe, Onescribe parent companies, subsidiaries, affiliates,
                and Our officers, employees, directors, agents, and
                representatives, harmless from and against any claim, demand,
                lawsuits,judicial proceeding, losses, liabilities, damages, and
                costs (including, without limitation, all damages, liabilities,
                settlements, and attorneys’ fees), due to or arising out of
                Partner’s breach of these Terms, or any violation of these Terms
                by any third party who may use Partners account.{" "}
              </li>
              <li>
                Notices: All notices under this Agreement shall be in writing
                and sent to the addresses specified above.
              </li>
              <li>
                Assignment - Partners shall not assign, transfer or delegate
                this Agreement to any third party without Onescribe’s prior
                written consent.{" "}
              </li>
              <li>
                Amendment/Modification - Onescribe reserves the right to amend
                the Term of this agreement without any prior notice. It will be
                Your responsibility to keep Yourself updated with the Terms from
                time to time. Your continued usage of the Onescribe Platform and
                Services would be deemed to be an acceptance of the Terms and
                the modifications thereto.
              </li>
              <li>
                Governing Law & Jurisdiction: These Terms shall be construed and
                enforced in accordance with the laws of India. If any dispute
                shall arise between the Parties hereto concerning the
                construction interpretation or application of any of the
                provisions of the Terms, such dispute shall be referred to the
                arbitration in accordance with Arbitration and Conciliation Act,
                1996. The arbitration shall be conducted in English and the
                venue shall be in courts of Thrissur (district) of Kerala.{" "}
              </li>
              <li>
                Force Majeure: Without prejudice to any other provision herein,
                Onescribe shall not be liable for any loss, damage or penalty as
                a result of any delay in or failure to deliver or otherwise
                perform hereunder due to any cause beyond Onescribe’s control,
                including, without limitation, acts of the User, embargo or
                other Governmental act, regulation or request affecting the
                conduct of Onescribe’s business, fire, explosion, accident,
                theft, vandalism, riot, acts of war, strikes or other labor
                difficulties, lightning, flood, windstorm or other acts of God.{" "}
              </li>
              <li>
                Waiver - Neither Party’s failure to exercise any right or remedy
                in consequence under these Terms will not constitute a waiver of
                such term or condition or prevent subsequent enforcement of such
                term or condition, unless agreed in writing.
              </li>
              <li>
                Severability – Onescribe Partners acknowledges and agrees that
                if any of the provisions of this agreement is deemed invalid,
                void, illegal, and unenforceable, such provision shall stand
                severed from this agreement and the remaining provisions of this
                agreement shall remain valid and enforceable and binding on the
                Parties.
              </li>
            </ol>
          </div>
        </Container>
        <Footer />
      </section>
    </>
  );
};

export default PartnerTandC;
